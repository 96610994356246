@import url( 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');

$primary-color: #FF4349;
$primary-color-darker: #e73f45;
$primary-color-light: rgba($primary-color, 0.5);
$primary-color-faint: rgba($primary-color, 0.25);
$common-font: "Noto Sans SC";
$max-width: 1000px;
$medium-width: 600px;
$common-font-size: 14px;
$title-font-size: $common-font-size*1.5;
$header-font-size: $common-font-size*2;
$multiplier: 1.25;

html {
    overflow: hidden;
    height: 100%;
}

body {
    height: 100%;
    overflow: auto;
    overscroll-behavior-y: none;
}

.p-a-s {
    padding: 0.5em;
}

.p-a-m {
    padding: 1em;
}

.p-a-l {
    padding: 2em;
}

.m-a-s {
    margin: 0.5em;
}

.m-a-m {
    margin: 1em;
}

.m-a-l {
    margin: 2em;
}

.full-width {
    width: 100%;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clickable-button {
    cursor: pointer;
}

.invisible {
    opacity: 0;
}

.divider-v {
    width: 100%;
    height: 2px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-color: $primary-color;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.half-opacity {
    opacity: 0.5;
}

.no-margin {
    margin: 0 !important;
}

.button {
    pointer-events: all;
    cursor: pointer;
}
a,
a:active,
a:hover,
a:visited {
    color: inherit;
}
